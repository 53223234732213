import { Grid, Paper, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { FaEthereum } from 'react-icons/fa';
import NumberFormatter from 'utils/NumberFormatter';
import SummaryBarChart from './SummaryBarChart';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default ({ sales_data, price_data, volume_data, stats }) => {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={4} className={classes.gridItem}>
          <Typography variant="h6" className={classes.gridItem}>
            Floor Price: <FaEthereum />
            <NumberFormatter number={stats['floor_price']} />
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.gridItem}>
          <Typography variant="h4">Collection Stats</Typography>
        </Grid>
        <Grid item xs={4} className={classes.gridItem}>
          <Typography variant="h6" className={classes.gridItem}>
            Market Cap: <FaEthereum />
            <NumberFormatter number={stats['market_cap']} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SummaryBarChart data={sales_data} ylabel="QUANTITY" title="Average Daily Sales" />
        </Grid>
        <Grid item xs={12}>
          <SummaryBarChart data={price_data} ylabel="ETH" title="Average Daily Price" />
        </Grid>
        <Grid item xs={12}>
          <SummaryBarChart data={volume_data} ylabel="ETH" title="Average Daily Volume" />
        </Grid>
      </Grid>
    </Paper>
  );
};
