export default () => {
  return [
    {
      title: 'Collection Appraiser',
      subtitle: 'Get An Inside Look On Any Collection On Opensea',
      description: (
        <ul>
          <li>Get answers to the most important questions you need to ask before buying into an NFT collection</li>
          <ul>
            <li>
              <b>Liquidity Risk:</b> Is there enough demand for this NFT collection so that I can sell in a short timespan?
            </li>
            <li>
              <b>Value Trend:</b> Are the NFTs in this collection increasing in value?
            </li>
            <li>
              <b>Sales Fee:</b> How much of my profits will have to go to the NFT creator+Opensea?
            </li>
          </ul>
          <li>Get the rarity data for any trait in a collection</li>
          <li>Get all the collection stats and links conveniently in one location</li>
        </ul>
      ),
      internalLink: '/collection-appraiser'
    },
    {
      title: 'Wallet Tracker',
      subtitle: 'Keep Tabs On How Multiple Wallets Perform',
      description: (
        <ul>
          <li>Get Summary Data About Any Wallet</li>
          <ul>
            <li>Estimated Wallet Value</li>
            <li>Wallet Cost</li>
            <li>Wallet Value Growth</li>
            <li>Asset Count</li>
            <li>Collection Diversity Inside of Wallet</li>
          </ul>
          <li>
            Get Data on All the Assets Inside of a Wallet like average price, value increases on any particular NFTs,relevant social links
            to keep track on the collections that assets belong to, and more!
          </li>
        </ul>
      ),
      internalLink: '/wallet-tracker'
    }
  ];
};
