import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Box, Paper, Typography } from '@material-ui/core';
import 'primeicons/primeicons.css';
import 'theme.css';
import 'primereact/resources/primereact.css';
import { PrimeIcons } from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { FaEthereum } from 'react-icons/fa';
import { Button } from 'primereact/button';
import NumberFormatter from 'utils/NumberFormatter';

const EthTemplate = (rowData, index) => {
  // console.log(index['field']);
  // console.log(rowData);
  return (
    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <FaEthereum />
      <NumberFormatter number={rowData[index['field']]} />
    </Typography>
  );
};

const PercentageTemplate = (rowData, index) => {
  const val = parseFloat(rowData[index['field']]);
  const floor = parseFloat(rowData['floor_price']);
  const diff = 1 + val;
  const orig = floor / diff;
  const diffVal = floor - orig;
  console.log(diffVal);
  const per = rowData[index['field']].toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 1
  });
  if (per.includes('-')) {
    return (
      <Typography variant="body1" color="secondary" style={{ display: 'flex', flexDirection: 'column' }}>
        <div>{per}</div>
        <Box display="flex" alignItems="center">
          (<FaEthereum />
          <NumberFormatter number={diffVal} />)
        </Box>
      </Typography>
    );
  } else {
    return (
      <Typography variant="body1" color="primary" style={{ display: 'flex', flexDirection: 'column' }}>
        <div>{per}</div>
        <Box display="flex" alignItems="center">
          (
          <FaEthereum />+
          <NumberFormatter number={diffVal} />)
        </Box>
      </Typography>
    );
  }
};
const EthPercentageTemplate = (rowData, index) => {
  return (
    <Typography variant="body1">
      <FaEthereum />
      <NumberFormatter number={rowData[index['field']]} />
    </Typography>
  );
};
const ActionsTemplate = (rowData, index) => {
  console.log(rowData);
  const link = 'https://opensea.io/collection/' + rowData['slug'];
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        textDecoration: 'none',
        color: '#FFF'
      }}
    >
      <Button icon="pi pi-external-link" />
    </a>
  );
};

export default React.memo(({ columns, data, columnFormats }) => {
  const [tableFilter, setTableFilter] = useState({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } });
  const onGlobalFilterChange = (event, filtersKey) => {
    const value = event.target.value;
    let filters = { ...tableFilter };
    filters['global'].value = value;
    setTableFilter(filters);
  };
  const SearchBar = (filtersKey) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">NFT General Stats</Typography>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            // type="search"
            value={tableFilter['global'].value || ''}
            onChange={(e) => onGlobalFilterChange(e, filtersKey)}
            placeholder="Search"
            spellCheck={false}
          />
        </span>
      </div>
    );
  };
  return (
    <Paper variant="outlined">
      <Tooltip target=".project" position="top" content="Collection name" />
      <Tooltip target=".floor_price" position="top" content="Current floor price" />
      <Tooltip target=".thirty_day_change" position="top" content="Change in price between 30D floor and current floor" />
      <Tooltip target=".seven_day_change" position="top" content="project" />
      <Tooltip target=".one_day_change" position="top" content="project" />
      <Tooltip target=".thirty_day_volume" position="top" content="project" />
      <Tooltip target=".thirty_day_sales" position="top" content="project" />
      <Tooltip target=".seven_day_volume" position="top" content="project" />
      <Tooltip target=".seven_day_sales" position="top" content="project" />
      <Tooltip target=".one_day_volume" position="top" content="project" />
      <Tooltip target=".one_day_sales" position="top" content="project" />
      <Tooltip target=".total_supply" position="top" content="project" />
      <Tooltip target=".num_owners" position="top" content="project" />
      <DataTable
        value={data}
        header={SearchBar}
        stripedRows
        filters={tableFilter}
        sortField="Floor Price"
        sortOrder={-1}
        // size="small"a
        responsiveLayout="stack"
        headerStyle={{ position: 'sticky', top: '0px' }}
      >
        <Column className="project" sortable field="project" header="Project" />
        <Column className="floor_price" sortable field="floor_price" header="FLR PRICE" body={EthTemplate} />
        {/* <Column sortable field="thirty_day_change" header="30D FLR" body={EthTemplate} />
        <Column sortable field="seven_day_change" header="7D FLR" body={EthTemplate} />
        <Column sortable field="one_day_change" header="1D FLR" body={EthTemplate} /> */}
        <Column className="thirty_day_change" sortable field="thirty_day_change" header="30D CHG" body={PercentageTemplate} />
        <Column className="seven_day_change" sortable field="seven_day_change" header="7D CHG" body={PercentageTemplate} />
        <Column className="one_day_change" sortable field="one_day_change" header="1D CHG" body={PercentageTemplate} />
        <Column className="thirty_day_volume" sortable field="thirty_day_volume" header="30D VOL" body={EthTemplate} />
        <Column className="thirty_day_sales" sortable field="thirty_day_sales" header="30D SALES" />
        <Column className="seven_day_volume" sortable field="seven_day_volume" header="7D VOL" body={EthTemplate} />
        <Column className="seven_day_sales" sortable field="seven_day_sales" header="7D SALES" />
        <Column className="one_day_volume" sortable field="one_day_volume" header="1D VOL" body={EthTemplate} />
        <Column className="one_day_sales" sortable field="one_day_sales" header="1D SALES" />
        <Column className="total_supply" sortable field="total_supply" header="SUPPLY" />
        <Column className="num_owners" sortable field="num_owners" header="OWNERS" />
        <Column field="slug" header="LINK" body={ActionsTemplate} />
      </DataTable>
    </Paper>
  );
});
