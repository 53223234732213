import { FaEthereum } from 'react-icons/fa';
import { Box } from '@material-ui/core';
export default ({ number, currencyType }) => {
  const n = parseFloat(number);
  if (currencyType === 'ETH') {
    return (
      <Box display="flex" alignItems="center">
        <FaEthereum style={{ height: '1.2rem', width: '0.5em' }} />
        {n.toLocaleString('en-US', { maximumFractionDigits: 3 })}
      </Box>
    );
  } else {
    return n.toLocaleString('en-US', { style: 'currency', currency: currencyType });
  }
};
