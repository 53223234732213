import { createContext, useContext } from 'react';

const PageContext = createContext({
  targetWallet: '',
  setTargetWallet: () => {}
});

const usePageContext = () => useContext(PageContext);
const PageProvider = ({ value, children }) => {
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export { PageProvider, usePageContext };
