import { createTheme } from '@material-ui/core/styles';
import './App.css';

const robotoCondensed = "'Roboto Condensed', sans-serif";
const cabin = "'Cabin', sans-serif";
const theme = createTheme({
  // overrides: {
  //   MUIDataTable: {
  //     responsiveScroll: {
  //       maxHeight: 'none'
  //     }
  //   }
  // },
  palette: {
    type: 'dark',
    primary: {
      main: '#06d6a0'
    },
    secondary: {
      main: '#f44435'
    },
    background: { paper: '#3a3a3a' }
  },
  shape: { borderRadius: 8 },
  typography: {
    fontFamily: cabin,
    h1: {
      fontFamily: robotoCondensed
    },
    h2: {
      fontFamily: robotoCondensed
    },
    h3: {
      fontFamily: robotoCondensed
    },
    h4: {
      fontFamily: robotoCondensed
    },
    h5: {
      fontFamily: robotoCondensed
    },
    h6: {
      fontFamily: robotoCondensed
    }
  }
});

export default theme;
