import { Paper, Typography, Box, makeStyles, Avatar } from '@material-ui/core';
import { VscBeaker, VscGraphLine, VscPieChart } from 'react-icons/vsc';
import { MdMoney, MdAttachMoney } from 'react-icons/md';
import { FaEthereum, FaHashtag, FaPercentage } from 'react-icons/fa';
import { GiPayMoney } from 'react-icons/gi';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%'
  },
  iconAvatar: {
    height: '40px',
    width: '40px',
    marginRight: '8px'
  },
  avatarIcon: {
    color: '#3a3a3a',
    height: '25px',
    width: '25px'
  }
}));

export default ({ title, value, desiredIcon, avatarColor }) => {
  const classes = useStyles();
  const appIcons = {
    liquidity: <VscBeaker className={classes.avatarIcon} />,
    valueTrend: <VscGraphLine className={classes.avatarIcon} />,
    secondaryFees: <VscPieChart className={classes.avatarIcon} />,
    ethPrice: <MdMoney className={classes.avatarIcon} />,
    walletValueETH: <FaEthereum className={classes.avatarIcon} />,
    walletValueUSD: <MdAttachMoney className={classes.avatarIcon} />,
    assetCount: <FaHashtag className={classes.avatarIcon} />,
    assetCost: <GiPayMoney className={classes.avatarIcon} />,
    percentage: <FaPercentage className={classes.avatarIcon} />
  };
  const colors = { green: '#08d6a0', yellow: '#ffd166', red: '#EF476F', blue: '#63D2FF', pink: '#FBCAEF', purple: '#C3BEF7' };
  const colormap = { Positive: '#08d6a0', Low: '#08d6a0', Medium: '#ffd166', Neutral: '#ffd166', High: '#EF476F', Negative: '#EF476F' };
  return (
    <Paper variant="outlined" className={classes.paper}>
      <Avatar className={classes.iconAvatar} style={{ backgroundColor: avatarColor ? colors[avatarColor] : colormap[value] }}>
        {appIcons[desiredIcon]}
      </Avatar>
      <Box className="cardContent" display="flex" flexDirection="column" alignItems="flex-start">
        <Typography variant="h5" style={{ color: colormap[value], textAlign: 'left' }}>
          {value}
        </Typography>
        <Typography variant="body1" style={{ textAlign: 'left' }}>
          {title}
        </Typography>
      </Box>
    </Paper>
  );
};
