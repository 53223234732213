import { Chart } from 'primereact/chart';
import React from 'react';

export default ({ title, labels, data, dotData, ylabel }) => {
  console.log(ylabel);
  const basicData = {
    labels: labels,
    datasets: [
      {
        label: title,
        data: data,
        fill: true,
        borderColor: '#06D6A0',
        borderWidth: 5,
        backgroundColor: 'rgba(6,214,160,0.2)',
        pointBackgroundColor: 'rgba(0,0,0,0.5)',
        pointRadius: 5,
        tension: 0.4
      }
    ]
  };
  const basicOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#FFF'
        }
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          beforeFooter: function (context) {
            // console.log(context[0]['label']);
            // console.log(Object.keys(dotData));
            if (Object.keys(dotData).includes(context[0]['label']) && dotData[[context[0]['label']]]['collection_slug'].length > 0) {
              const per = dotData[[context[0]['label']]]['total_price'][0].toLocaleString(undefined, {
                style: 'percent',
                minimumFractionDigits: 1
              });
              const col = dotData[[context[0]['label']]]['collection_name'][0];
              return `${per} | ${col}`;
            }
          },
          footer: function (context) {
            if (Object.keys(dotData).includes(context[0]['label']) && dotData[[context[0]['label']]]['collection_slug'].length > 1) {
              const per = dotData[[context[0]['label']]]['total_price'][1].toLocaleString(undefined, {
                style: 'percent',
                minimumFractionDigits: 1
              });
              const col = dotData[[context[0]['label']]]['collection_name'][1];
              return `${per} | ${col}`;
            }
          },
          afterFooter: function (context) {
            if (Object.keys(dotData).includes(context[0]['label']) && dotData[[context[0]['label']]]['collection_slug'].length > 2) {
              const per = dotData[[context[0]['label']]]['total_price'][2].toLocaleString(undefined, {
                style: 'percent',
                minimumFractionDigits: 1
              });
              const col = dotData[[context[0]['label']]]['collection_slug'][2];
              return `${per} | ${col}`;
            }
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'TIME (UTC)',
          color: '#FFF',
          font: {
            size: 18,
            family: 'Roboto Condensed, sans-serif'
          }
        },
        ticks: {
          color: '#FFF'
        },
        grid: {
          color: 'rgba(255,255,255,.15)'
        }
      },
      y: {
        title: {
          display: true,
          text: ylabel,
          color: '#FFF',
          font: {
            size: 18,
            family: 'Roboto Condensed, sans-serif'
          }
        },
        ticks: {
          color: '#FFF'
        },
        grid: {
          color: 'rgba(255,255,255,.15)'
        }
      }
    }
  };

  return <Chart type="line" data={basicData} options={basicOptions} />;
};
