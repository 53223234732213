import { Box, Button, ButtonGroup, Container, Hidden, makeStyles, Menu, MenuItem, Paper, Typography } from '@material-ui/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import MetaMaskAuth from './metamask-auth';
import SocialsBar from './SocialsBar';

const NavMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" variant="outlined" onClick={handleClick}>
        Tools
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {/* <MenuItem component={Link} to="/general-stats" onClick={handleClose}>
          General Stats
        </MenuItem> */}
        <MenuItem component={Link} to="/collection-appraiser" onClick={handleClose}>
          Collection Appraiser
        </MenuItem>
        <MenuItem component={Link} to="/wallet-tracker" onClick={handleClose}>
          Wallet Tracker
        </MenuItem>
      </Menu>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: '40px',
    height: '40px',
    padding: theme.spacing(1)
  },
  icon: {
    width: '24px',
    height: '24px'
  },
  boxContent: {
    display: 'flex',
    alignItems: 'center'
  },
  navButton: {
    marginLeft: theme.spacing(1)
  }
}));

export default () => {
  const classes = useStyles();
  return (
    <Paper variant="outlined" square style={{ height: '60px' }}>
      <Container
        maxWidth="xl"
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Box className={classes.boxContent}>
          <Typography component={Link} to="/" variant="h5" style={{ marginRight: '8px', textDecoration: 'none', color: '#FFF' }}>
            TokenTuna
          </Typography>
          <SocialsBar twitter="ethermagellan" opensea="mekaverse" discord="mechanubis" />
        </Box>
        <Box className={classes.boxContent}>
          <Hidden only={['lg', 'xl']}>
            <NavMenu />
          </Hidden>
          <Hidden only={['xs', 'sm', 'md']}>
            <ButtonGroup>
              {/* <Button variant="outlined" component={Link} to="/general-stats" className={classes.navButton}>
                General Stats
              </Button> */}
              <Button variant="outlined" component={Link} to="/collection-appraiser" className={classes.navButton}>
                Collection Appraiser
              </Button>
              <Button variant="outlined" component={Link} to="/wallet-tracker" className={classes.navButton}>
                Wallet Tracker
              </Button>
            </ButtonGroup>
          </Hidden>
          <MetaMaskAuth onAddressChanged={(address) => {}} />
        </Box>
      </Container>
    </Paper>
  );
};
