import React, { useEffect, useState } from 'react';
import { Button, Chip } from '@material-ui/core';
import { useAuthContext } from 'AuthContext';

function isMobileDevice() {
  return 'ontouchstart' in window || 'onmsgesturechange' in window;
}

async function connect(onConnected) {
  if (!window.ethereum) {
    alert('Get MetaMask!');
    return;
  }

  const accounts = await window.ethereum.request({
    method: 'eth_requestAccounts'
  });

  onConnected(accounts[0]);
}

async function checkIfWalletIsConnected(onConnected) {
  if (window.ethereum) {
    const accounts = await window.ethereum.request({
      method: 'eth_accounts'
    });

    if (accounts.length > 0) {
      const account = accounts[0];
      onConnected(account);
      return;
    }

    if (isMobileDevice()) {
      await connect(onConnected);
    }
  }
}

export default function MetaMaskAuth({ onAddressChanged }) {
  const { userAddress, setUserAddress } = useAuthContext();

  useEffect(() => {
    checkIfWalletIsConnected(setUserAddress);
  }, []);

  useEffect(() => {
    onAddressChanged(userAddress);
  }, [userAddress]);

  return userAddress ? (
    <Button disabled variant="outlined" color="primary" onClick={() => connect(setUserAddress)} style={{ marginLeft: '8px' }}>
      Connected: {Address(userAddress)}
    </Button>
  ) : (
    <Connect setUserAddress={setUserAddress} />
  );
}

function Connect({ setUserAddress }) {
  if (isMobileDevice()) {
    const dappUrl = 'master.d36z78fi23vxpe.amplifyapp.com'; // TODO enter your dapp URL. For example: https://uniswap.exchange. (don't enter the "https://")
    const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl;
    return (
      <a href={metamaskAppDeepLink}>
        <Button color="outlined" variant="outlined" style={{ marginLeft: '8px' }}>
          Connect to MetaMask
        </Button>
      </a>
    );
  }

  return (
    // <Chip color="primary" onDelete={handleDelete} icon={<FaceIcon />} />
    <Button variant="outlined" color="primary" onClick={() => connect(setUserAddress)} style={{ marginLeft: '8px' }}>
      Connect to MetaMask
    </Button>
  );
}

function Address(userAddress) {
  const address = userAddress.substring(0, 5) + '…' + userAddress.substring(userAddress.length - 4);
  return address;
}
