import { Box, Typography } from '@material-ui/core';
import { Chart } from 'primereact/chart';
import React from 'react';

export default ({ chart_data }) => {
  var chartData = chart_data;
  chartData['datasets'][0]['backgroundColor'] = ['#08d6a0', '#ffd166', '#EF476F', '#63D2FF', '#FBCAEF', '#C3BEF7'];
  chartData['datasets'][0]['hoverBackgroundColor'] = ['#30F8C2', '#FFDD8C', '#F37693', '#88DDFF', '#FCD8F3', '#D3CFF9'];

  const lightOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          fontSize: '20px',
          color: '#FFF'
        }
      }
    }
  };
  return (
    <Box width="100%" height="400px" display="flex" flexDirection="column" justifyContent="space-evenly" alignItems="center">
      <Typography variant="h4">Collection Distribution</Typography>
      <Chart type="pie" data={chartData} options={lightOptions} style={{ width: '360px', height: '360px' }} />
    </Box>
  );
};
