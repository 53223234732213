import { Paper, Container, Typography, Box, makeStyles, Button } from '@material-ui/core';
import SocialsBar from './SocialsBar';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: '40px',
    height: '40px',
    padding: theme.spacing(1)
  },
  icon: {
    width: '24px',
    height: '24px'
  },
  boxContent: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export default () => {
  const classes = useStyles();
  return (
    <Paper variant="outlined" square style={{ height: '60px' }}>
      <Container
        maxWidth="xl"
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Box width={200} />
        <Box className={classes.boxContent}>
          <SocialsBar twitter="ethermagellan" opensea="mekaverse" discord="mechanubis" />
        </Box>
        <Box width={240}>
          <Button target="_blank" rel="noreferrer" component="a" href="https://www.ethermagellan.com" variant="outlined">
            <Typography style={{ fontWeight: 'bold' }} variant="body1">
              An EtherMagellan App
            </Typography>
          </Button>
        </Box>
      </Container>
    </Paper>
  );
};
