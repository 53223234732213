import { Box, Divider, makeStyles, Paper, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { usePageContext } from 'pages/WalletTracker/PageContext';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  iconAvatar: {
    height: '40px',
    width: '40px',
    marginRight: '8px'
  },
  avatarIcon: {
    color: '#3a3a3a',
    height: '25px',
    width: '25px'
  },
  listRoot: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 255
  }
}));

const SelectList = ({ wallets, updateWallets }) => {
  const classes = useStyles();
  const { targetWallet, setTargetWallet } = usePageContext();
  const handleRemoveItem = (value) => () => {
    updateWallets(wallets.filter((item) => item !== value));
  };

  return (
    <List className={classes.listRoot}>
      {wallets.map((wallet) => {
        const labelId = `checkbox-list-label-${wallet}`;
        return (
          <>
            <ListItem key={wallet} role={undefined} dense button onClick={() => setTargetWallet(wallet)}>
              {/* <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(item.wallet) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon> */}
              <ListItemText
                id={labelId}
                primary={
                  <Typography variant="body2" style={{ fontFamily: "'Roboto Mono', sans-serif", overflowWrap: 'anywhere' }}>
                    {wallet}
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" style={{ padding: '9px' }} onClick={handleRemoveItem(wallet)}>
                  <ClearIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </>
        );
      })}
    </List>
  );
};

export default () => {
  const { targetWallet, setTargetWallet } = usePageContext();
  const classes = useStyles();
  const [walletText, setWalletText] = useState('');
  var tracked_wallets = JSON.parse(localStorage.getItem('tracked_wallets'));
  if (!tracked_wallets) {
    tracked_wallets = [];
  }
  const [wallets, updateWallets] = useState(tracked_wallets);
  const handleAddItem = () => {
    updateWallets((wallets) => [...wallets, walletText]);
    setWalletText('');
    setTargetWallet(walletText);
  };
  useEffect(() => {
    localStorage.setItem('tracked_wallets', JSON.stringify(wallets));
  }, [wallets]);
  return (
    <Paper variant="outlined" className={classes.paper}>
      <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <Typography variant="h4">Tracked Wallets</Typography>
        <Box width="100%" display="flex" justifyContent="space-between">
          <div className="p-inputgroup">
            <InputText
              id="wallet"
              value={walletText}
              placeholder="Add Wallet Address"
              onChange={(e) => setWalletText(e.target.value)}
              style={{ width: '100%' }}
              onKeyPress={(e) => {
                if (e.key == 'Enter') {
                  handleAddItem();
                }
              }}
            />
            <Button variant="outlined" color="primary" style={{ minWidth: '3rem' }} onClick={handleAddItem}>
              <AddIcon />
            </Button>
          </div>
        </Box>
        <Box height="100%" style={{ backgroundColor: 'rgba(255,255,255,0.05)', border: '1px solid rgba(255, 255, 255, 0.12)' }}>
          <SelectList wallets={wallets} updateWallets={updateWallets} />
        </Box>
      </Box>
    </Paper>
  );
};
