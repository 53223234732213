import CollectionAppraiser from 'pages/CollectionAppraiser';
import GeneralStats from 'pages/GeneralStats';
import Home from 'pages/Home';
import WalletTracker from 'pages/WalletTracker';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
// your components
const MissingPage = () => <h1>404</h1>;

const routes = (
  <Routes>
    <Route path="/" exact element={<Home />} />ß
    <Route path="/general-stats" element={<GeneralStats />} />
    <Route path="/wallet-tracker" element={<WalletTracker />} />
    <Route path="/collection-appraiser" element={<CollectionAppraiser />} />
    <Route component={MissingPage} />
  </Routes>
);

export default routes;
