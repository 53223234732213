import { Paper, Typography, makeStyles, Box, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  featuredImg: {
    height: '150px',
    width: '100%'
    // borderRadius: '4px 4px 0px 0px'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  bodyText: {
    textAlign: 'left'
  },
  featuredTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export default ({ projectName, projectDescription, projectImgSrc, projectOpenSeaUrl }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} variant="outlined">
      {projectName == 'Featured Project' ? (
        <Box p={2}>
          <Typography variant="h5" className={classes.featuredTitle}>
            {projectName}
          </Typography>
          <Typography variant="body1" className={classes.featuredTitle}>
            Want to be Featured Here?
          </Typography>
          <Button
            color="primary"
            variant="outlined"
            target="_blank"
            rel="noreferrer"
            component="a"
            href={'https://twitter.com/ethermagellan'}
          >
            DM Me On Twitter
          </Button>
        </Box>
      ) : (
        <>
          <img src={projectImgSrc} className={classes.featuredImg} />
          <Box p={2}>
            <Typography variant="h5" className={classes.featuredTitle}>
              {projectName}
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              {projectDescription}
            </Typography>
          </Box>
        </>
      )}
    </Paper>
  );
};
