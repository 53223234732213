import logo from './logo.svg';
import Home from 'pages/GeneralStats';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from 'Theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';
import NavBar from 'components/NavBar';
import FooterBar from 'components/FooterBar';
import routes from './routes';
import Amplify, { API } from 'aws-amplify';
import awsconfig from './aws-exports';
import { AuthProvider } from 'AuthContext';
import { useState, useMemo } from 'react';

Amplify.configure(awsconfig);

function App() {
  const [userAddress, setUserAddress] = useState('');
  const context = useMemo(() => ({ userAddress, setUserAddress }), [userAddress]);
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider value={context}>
        <CssBaseline />
        <Router>
          <div className="App" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh' }}>
            <div className="PageContent">
              <NavBar />
              {routes}
            </div>
            <FooterBar />
          </div>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
