import { Box, Typography, Paper, makeStyles } from '@material-ui/core';
import OpenSeaImg from 'assets/opensea.png';
import { FaEthereum } from 'react-icons/fa';
import NumberFormatter from 'utils/NumberFormatter';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '24px',
    height: '24px'
  },
  boxContent: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  linkStyle: {
    textDecoration: 'none',
    color: '#FFF'
  }
}));

export default ({ index, name, slug, value, etherFlag }) => {
  const classes = useStyles();
  const link = `https://opensea.io/collection/${slug}`;

  return (
    <Paper variant="outlined" style={{ height: '100%' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} height="100%">
        <Box display="flex" flexDirection="column" mr={1} justifyContent="center" alignItems="center" width={60}>
          <Typography variant="h5">#{index + 1}</Typography>
          <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>
            {etherFlag && <FaEthereum />}
            {etherFlag ? <NumberFormatter number={value} /> : <>{value}</>}
            {!etherFlag && <> NFTs</>}
          </Typography>
        </Box>
        <Box width="100%">
          <Typography variant="body2">{name}</Typography>
        </Box>
        <Box ml={1} width={60}>
          <a href={link} target="_blank" rel="noopener noreferrer" className={classes.linkStyle}>
            <img src={OpenSeaImg} className={classes.icon} />
          </a>
        </Box>
      </Box>
    </Paper>
  );
};
