// import { NodeService } from './NodeService';
import { Box, Typography } from '@material-ui/core';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { TreeTable } from 'primereact/treetable';
import { useState } from 'react';

export default ({ data }) => {
  console.log(data);
  const [nodes, setNodes] = useState(data);
  const [globalFilter1, setGlobalFilter1] = useState(null);
  //   const nodeservice = new NodeService();

  const treeTableFuncMap = {
    globalFilter1: setGlobalFilter1
  };

  const getHeader = (globalFilterKey) => {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <Typography variant="h4">Trait Rarity Finder</Typography>
        <div className="p-text-right">
          <div className="p-input-icon-left">
            <i className="pi pi-search"></i>
            <InputText onInput={(e) => treeTableFuncMap[`${globalFilterKey}`](e.target.value)} placeholder="Search Traits" />
          </div>
        </div>
      </Box>
    );
  };

  let header1 = getHeader('globalFilter1');
  const traits = data['traits'];
  const total_supply = data['stats']['count'];
  console.log(total_supply);
  var arr = [];
  var children = [];
  var expandKeys = [];
  Object.entries(traits).forEach(([key, value], index) => {
    console.log(`${key} | ${index}: ${value}`);
    Object.entries({ ...value }).forEach(([childKey, childValue], childIndex) => {
      children.push({
        key: `${index}-${childIndex}`,
        data: {
          trait: childKey,
          count: childValue,
          percentage: (childValue / total_supply).toLocaleString('en', { style: 'percent', minimumFractionDigits: 3 })
        }
      });
    });
    arr.push({ key: `${index}`, data: { trait: key }, children: children });
    expandKeys.push({ index: true });
  });
  //   const result = Object.entries(traits).map(([key, values, index]) => ({ key, index, ...values }));
  console.log(arr);
  console.log(expandKeys);
  return (
    <div className="card">
      <TreeTable value={arr} scrollable globalFilter={globalFilter1} header={header1} scrollHeight="480px" expandedKeys={expandKeys}>
        <Column sortable field="trait" header="Trait" expander style={{ width: '60%' }}></Column>
        <Column sortable field="percentage" header="Percentage"></Column>
        <Column sortable field="count" header="Count"></Column>
        {/* <Column field="type" header="Type" filterPlaceholder="Filter by type"></Column> */}
      </TreeTable>
    </div>
  );
};
