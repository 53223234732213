import { Container, Grid, Paper } from '@material-ui/core';
import { AuthGuard } from 'AuthContext';
import { API } from 'aws-amplify';
import StatCard from 'components/StatCard';
import { useEffect, useMemo, useState } from 'react';
import CurrencyFormatter from 'utils/CurrencyFormatter';
import PercentageFormatter from 'utils/PercentageFormatter';
import CollectionDistributionChart from './components/CollectionDistributionChart';
import StatsTable from './components/StatsTable';
import WalletList from './components/WalletList';
import { PageProvider } from './PageContext';
import sampleWallet from './SampleWallet';

export default () => {
  const [targetWallet, setTargetWallet] = useState('');
  const [pageData, setPageData] = useState(sampleWallet);
  const context = useMemo(() => ({ targetWallet, setTargetWallet }), [targetWallet]);
  var pageStorage = JSON.parse(localStorage.getItem('wallet_tracker'));
  useEffect(() => {
    console.log(targetWallet);
    if (targetWallet != null && targetWallet.length == 42) {
      //Check Storage if the targetWallet has been searched
      if (pageStorage && pageStorage['wallets'].includes(targetWallet)) {
        // Storage exists and slug has been searched
        setPageData(pageStorage['data'][targetWallet]);
      } else if (pageStorage) {
        //Storage exists but targetWallet not inside
        API.get('nfttrackerapi', `/wallet/${targetWallet}`, {})
          .then((response) => {
            if (pageStorage['wallets'].length >= 10) {
              delete pageStorage['data'][pageStorage['wallets'].shift()];
            }
            pageStorage['wallets'].push(targetWallet);
            pageStorage['data'][targetWallet] = response;
            localStorage.setItem('wallet_tracker', JSON.stringify(pageStorage));
            setPageData(response);
          })
          .catch((error) => {
            console.log(error.response);
          });
      } else {
        //Need to create storage space
        API.get('nfttrackerapi', `/wallet/${targetWallet}`, {})
          .then((response) => {
            // console.log(response);
            pageStorage = { wallets: [targetWallet], data: {} };
            pageStorage['data'][targetWallet] = response;
            localStorage.setItem('wallet_tracker', JSON.stringify(pageStorage));
            setPageData(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [targetWallet]);
  return (
    <AuthGuard>
      <PageProvider value={context}>
        <Container maxWidth="xl" style={{ paddingTop: '24px', paddingBottom: '24px' }}>
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} md={6} lg={4} style={{ display: 'flex' }}>
              <WalletList />
            </Grid>
            <Grid item xs={12} md={6} lg={4} style={{ display: 'flex' }}>
              <Paper variant="outlined" style={{ height: '100%', width: '100%', padding: '16px' }}>
                <CollectionDistributionChart chart_data={pageData['pie_data']} />
              </Paper>
            </Grid>
            <Grid item xs={12} lg={4} style={{ display: 'flex' }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <StatCard
                    title="Eth Price"
                    value={<CurrencyFormatter number={pageData['stats']['eth_price']} currencyType="USD" />}
                    desiredIcon="ethPrice"
                    avatarColor="green"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StatCard title="Total Assets" value={pageData['stats']['total_assets']} desiredIcon="assetCount" avatarColor="yellow" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StatCard
                    title="Estimated Wallet Value (ETH)"
                    value={<CurrencyFormatter number={pageData['stats']['wallet_value_eth']} currencyType="ETH" />}
                    desiredIcon="walletValueETH"
                    avatarColor="red"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StatCard
                    title="Estimated Wallet Value (USD)"
                    value={<CurrencyFormatter number={pageData['stats']['wallet_value_usd']} currencyType="USD" />}
                    desiredIcon="walletValueUSD"
                    avatarColor="blue"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StatCard
                    title="Total Cost"
                    value={<CurrencyFormatter number={pageData['stats']['total_cost']} currencyType="ETH" />}
                    desiredIcon="assetCost"
                    avatarColor="pink"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StatCard
                    title="Growth"
                    value={<PercentageFormatter number={pageData['stats']['growth']} />}
                    desiredIcon="percentage"
                    avatarColor="purple"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {/* <OpenSeaDataTable data={OpenSeaData.data} columns={OpenSeaData.columns} options={OpenSeaData.options} /> */}
              {console.log(pageData['table_data'])}
              <StatsTable data={pageData['table_data']} />
              {/* <WalletSearch data={collectiondata} collections={collectionData} assets={assetData} /> */}
            </Grid>
          </Grid>
        </Container>
      </PageProvider>
    </AuthGuard>
  );
};
