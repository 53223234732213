import { Typography } from '@material-ui/core';
import { Chart } from 'primereact/chart';
import React from 'react';

export default ({ title, ylabel, data }) => {
  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 2.5,
      plugins: {
        legend: {
          display: false,
          labels: {
            color: '#FFF'
          }
        }
      },
      scales: {
        x: {
          title: {
            font: {
              size: 18,
              family: 'Roboto Condensed, sans-serif'
            }
          },
          ticks: {
            color: '#FFF'
          },
          grid: {
            color: 'rgba(255,255,255,.15)'
          }
        },
        y: {
          title: {
            display: true,
            text: ylabel,
            color: '#FFF',
            font: {
              size: 18,
              family: 'Roboto Condensed, sans-serif'
            }
          },
          ticks: {
            color: '#FFF'
          },
          grid: {
            color: 'rgba(255,255,255,.15)'
          }
        }
      }
    };

    return {
      basicOptions
    };
  };
  const basicData = {
    labels: ['30-DAY', '7-DAY', '1-DAY'],
    datasets: [
      {
        label: title,
        backgroundColor: 'rgba(8, 214, 160,0.5)',
        borderColor: 'rgb(8, 214, 160)',
        borderWidth: 2,
        data: data
      }
    ]
  };
  const { basicOptions } = getLightTheme();

  return (
    <div>
      <div className="card">
        <Typography variant="h6">{title}</Typography>
        <Chart type="bar" data={basicData} options={basicOptions} />
      </div>
    </div>
  );
};
