import { Button, ButtonGroup, makeStyles } from '@material-ui/core';
import OpenSeaImg from 'assets/opensea_green.png';
import { FaDiscord, FaExternalLinkAlt, FaInstagram, FaMediumM, FaTelegramPlane, FaTwitter } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  socialCard: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  icon: {
    width: 20,
    height: 20
  },
  button: {
    padding: theme.spacing(1)
  }
}));

export default ({ opensea, discord, instagram, twitter, medium, telegram, external, nft, vertical }) => {
  const classes = useStyles();

  return (
    <ButtonGroup color="primary" aria-label="outlined primary button group" orientation={vertical && 'vertical'}>
      {opensea && (
        <Button target="_blank" rel="noreferrer" component="a" href={`https://opensea.io/collection/${opensea}`} className={classes.button}>
          <img src={OpenSeaImg} className={classes.icon} />
        </Button>
      )}
      {nft && (
        <Button target="_blank" rel="noreferrer" component="a" href={nft} className={classes.button}>
          <img src={OpenSeaImg} className={classes.icon} />
        </Button>
      )}
      {discord && (
        <Button target="_blank" rel="noreferrer" component="a" href={`${discord}`} className={classes.button}>
          <FaDiscord className={classes.icon} />
        </Button>
      )}
      {instagram && (
        <Button target="_blank" rel="noreferrer" component="a" href={`https://www.instagram.com/${instagram}/`} className={classes.button}>
          <FaInstagram className={classes.icon} />
        </Button>
      )}
      {twitter && (
        <Button target="_blank" rel="noreferrer" component="a" href={`https://twitter.com/${twitter}`} className={classes.button}>
          <FaTwitter className={classes.icon} />
        </Button>
      )}
      {medium && (
        <Button target="_blank" rel="noreferrer" component="a" href={`https://${medium}.medium.com`} className={classes.button}>
          <FaMediumM className={classes.icon} />
        </Button>
      )}
      {telegram && (
        <Button target="_blank" rel="noreferrer" component="a" href={`${telegram}`} className={classes.button}>
          <FaTelegramPlane className={classes.icon} />
        </Button>
      )}
      {external && (
        <Button target="_blank" rel="noreferrer" component="a" href={`${external}`} className={classes.button}>
          <FaExternalLinkAlt className={classes.icon} />
        </Button>
      )}
    </ButtonGroup>
  );
};
