import { Avatar, Box, Chip, Container, Divider, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import SlugImg from 'assets/slug.png';
import { AuthGuard } from 'AuthContext';
import { API } from 'aws-amplify';
import SocialsBar from 'components/SocialsBar';
import StatCard from 'components/StatCard';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import CountFormatter from 'utils/CountFormatter';
import TimestampFormatter from 'utils/TimestampFormatter';
import CollectionStats from './components/CollectionStats';
import TraitSearch from './components/TraitSearch';

const PageContext = createContext({
  slug: '',
  setSlug: () => {}
});

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  chip: {
    margin: theme.spacing(0.25)
  }
}));
const ChipSection = ({ slugs }) => {
  const classes = useStyles();
  const { slug, setSlug } = useContext(PageContext);
  console.log('creating chips');
  const chips = slugs.map((item) => (
    <Chip label={`${item}`} onClick={() => setSlug(item)} color="primary" variant="outlined" clickable className={classes.chip} />
  ));
  return <div>{chips}</div>;
};
const SearchSection = () => {
  const { slug, setSlug } = useContext(PageContext);
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <Box display="flex" width="100%">
      <div className="p-inputgroup">
        <Button icon="pi pi-search" style={{ width: '45px' }} onClick={() => setSlug(searchTerm)} />
        <InputText
          placeholder="Keyword"
          style={{ width: '100%' }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search For a Collection By Slug"
          spellCheck={false}
          onKeyPress={(e) => {
            if (e.key == 'Enter') {
              setSlug(searchTerm);
            }
          }}
        />
      </div>
    </Box>
  );
};
// API.get('nfttrackerapi', '/collection/mekaverse', {})
// .then((response) => {
//   console.log(response);
// })
// .catch((error) => {
//   console.log(error.response);
// });
export default () => {
  const classes = useStyles();
  const [slug, setSlug] = useState('');
  const [pageData, setPageData] = useState(null);
  const context = useMemo(() => ({ slug, setSlug }), [slug]);
  var pageStorage = JSON.parse(localStorage.getItem('collection_appraiser'));
  useEffect(() => {
    console.log(slug);
    if (slug != null && slug != '') {
      //Check Storage if the slug has been searched recently
      if (pageStorage && pageStorage['queue'].includes(slug)) {
        // Storage exists and slug has been searched
        setPageData(pageStorage['data'][slug]);
      } else if (pageStorage) {
        //Storage exists but slug not inside
        API.get('nfttrackerapi', `/collection/${slug}`, {})
          .then((response) => {
            if (pageStorage['queue'].length >= 10) {
              delete pageStorage['data'][pageStorage['queue'].shift()];
            }
            pageStorage['queue'].push(slug);
            pageStorage['data'][slug] = response;
            localStorage.setItem('collection_appraiser', JSON.stringify(pageStorage));
            setPageData(response);
          })
          .catch((error) => {
            console.log(error.response);
          });
      } else {
        //Need to create storage space
        API.get('nfttrackerapi', `/collection/${slug}`, {})
          .then((response) => {
            pageStorage = { queue: [slug], data: {} };
            pageStorage['data'][slug] = response;
            localStorage.setItem('collection_appraiser', JSON.stringify(pageStorage));
            setPageData(response);
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    }
  }, [slug]);
  var fee;
  var price_data;
  var sales_data;
  var volume_data;
  if (pageData != null) {
    sales_data = [
      Math.round(pageData['stats']['thirty_day_sales'] / 30),
      Math.round(pageData['stats']['seven_day_sales'] / 7),
      Math.round(pageData['stats']['one_day_sales'])
    ];
    price_data = [
      pageData['stats']['thirty_day_average_price'],
      pageData['stats']['seven_day_average_price'],
      pageData['stats']['one_day_average_price']
    ];
    volume_data = [
      pageData['stats']['thirty_day_volume'] / 30,
      pageData['stats']['seven_day_volume'] / 7,
      pageData['stats']['one_day_volume']
    ];
    fee = (parseInt(pageData['dev_seller_fee_basis_points']) + parseInt(pageData['opensea_seller_fee_basis_points'])) / 10000;
    //LIQUDITY RISK
    var liquidity_risk;
    if (sales_data[1] < 1) {
      // HIGH RISK
      liquidity_risk = 'High';
    } else if (sales_data[2] < 2) {
      // MEDIUM RISK
      liquidity_risk = 'Medium';
    } else {
      // LOW RISK
      liquidity_risk = 'Low';
    }
    //VALUE TREND
    var value_trend;
    // console.log(`TRENDING: ${price_data[1] / price_data[0]}`);
    if (price_data[1] / price_data[0] - fee < 0.85) {
      value_trend = 'Negative';
    } else if (price_data[1] / price_data[0] - fee > 1.15) {
      value_trend = 'Positive';
    } else {
      value_trend = 'Neutral';
    }
  }
  return (
    <AuthGuard>
      <PageContext.Provider value={context}>
        <Container maxWidth="xl" style={{ paddingTop: '24px', paddingBottom: '24px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper variant="outlined" style={{ display: 'flex', alignItems: 'center', height: '100%', padding: '12px' }}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <Typography variant="h4" style={{ marginRight: '8px' }}>
                      COLLECTION APPRAISER
                    </Typography>
                    <img src={SlugImg} style={{ borderRadius: '8px', maxWidth: '600px', width: '100%' }} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <SearchSection />
                    {pageStorage && <ChipSection slugs={pageStorage['queue']} />}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {pageData && (
              <>
                <Grid item xs={12}>
                  <Paper variant="outlined" className={classes.paper}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box
                        className="stats"
                        display="flex"
                        flexDirection="column"
                        width={200}
                        alignItems="flex-start"
                        justifyContent="center"
                      >
                        <Box width="fit-content">
                          <Typography variant="body1">
                            <CountFormatter number={pageData['stats']['total_supply']} /> NFTS
                          </Typography>
                          <Divider />
                          <Typography variant="body1">
                            <CountFormatter number={pageData['stats']['num_owners']} /> Owners
                          </Typography>
                        </Box>
                      </Box>
                      <Typography variant="h4" style={{ display: 'flex', alignItems: 'center' }}>
                        {pageData['name']}
                        <Avatar style={{ marginLeft: '4px', marginRight: '4px' }} src={pageData['image_url']} />
                      </Typography>
                      <Box width={200} display="flex" justifyContent="flex-end" alignItems="center">
                        <SocialsBar
                          opensea={slug}
                          discord={pageData['discord_url']}
                          instagram={pageData['instagram_username']}
                          twitter={pageData['twitter_username']}
                          medium={pageData['medium_username']}
                          telegram={pageData['telegram_url']}
                          external={pageData['external_url']}
                        />
                      </Box>
                    </Box>
                    <Typography variant="body1" style={{ textAlign: 'left', marginTop: '24px' }}>
                      {pageData['description']}
                    </Typography>
                    <Box pt={2} style={{ color: 'rgba(255,255,255,0.2)' }}>
                      Last Updated: <TimestampFormatter timestamp={pageData['timestamp']} />
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Grid container spacing={1} style={{ height: '100%', margin: 0 }}>
                    <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                      <StatCard title="Liquidity Risk" value={liquidity_risk} desiredIcon="liquidity" />
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                      <StatCard title="Value Trend" value={value_trend} desiredIcon="valueTrend" />
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                      <StatCard
                        title="Sales Fee"
                        value={fee.toLocaleString('en', { style: 'percent', minimumFractionDigits: 2 })}
                        desiredIcon="secondaryFees"
                        avatarColor={fee <= 0.05 ? 'green' : fee <= 0.1 ? 'yellow' : 'red'}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ paddingBottom: 0 }}>
                      <CollectionStats
                        sales_data={sales_data}
                        price_data={price_data}
                        volume_data={volume_data}
                        stats={pageData['stats']}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Paper variant="outlined" className={classes.paper}>
                    <TraitSearch data={pageData} />
                  </Paper>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </PageContext.Provider>
    </AuthGuard>
  );
};
