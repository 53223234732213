import { Box, Typography } from '@material-ui/core';
import MetaMaskAuth from 'components/metamask-auth';
import { createContext, useContext } from 'react';
const AuthContext = createContext({
  userAddress: '',
  setUserAddress: () => {}
});

const useAuthContext = () => useContext(AuthContext);
const AuthProvider = ({ value, children }) => {
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthGuard = ({ children }) => {
  const { userAddress, setUserAddress } = useAuthContext();
  return (
    <>
      {userAddress && userAddress != '' ? (
        <>{children}</>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          maxWidth="600px"
          height="400px"
          margin="auto"
        >
          <Typography variant="h4" style={{ marginBottom: '16px' }}>
            PLEASE LOGIN TO
            <br /> YOUR METAMASK ACCOUNT
          </Typography>
          <MetaMaskAuth onAddressChanged={(address) => {}} />
        </Box>
      )}
    </>
  );
};

export { AuthProvider, useAuthContext, AuthGuard };
