import { TabView, TabPanel } from 'primereact/tabview';
import LineChart from 'components/LineChart';
import { Grid, Paper, Typography, Box } from '@material-ui/core';
import CollectionCard from './CollectionCard';

const samples = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
export default ({ data }) => {
  console.log(data);
  return (
    <Paper variant="outlined" style={{ padding: '16px' }}>
      <TabView style={{ justifyContent: 'center' }}>
        <TabPanel header="Volume">
          <LineChart title="Volume" labels={data['hourly_label']} data={data['wph']} dotData={data['pwph']} ylabel="ETH" />
          <Grid container spacing={1} style={{ marginTop: '20px' }}>
            <Grid item xs={12}>
              <Typography variant="h5">Top Volume (24Hrs)</Typography>
            </Grid>
            {data['top_eth']['total_price'].map((item, index) => (
              <Grid item xs={12} md={6} lg={3}>
                <CollectionCard
                  index={index}
                  name={data['top_eth']['collection_name'][index]}
                  slug={data['top_eth']['collection_slug'][index]}
                  value={data['top_eth']['total_price'][index]}
                  etherFlag={true}
                />
              </Grid>
            ))}
          </Grid>
        </TabPanel>
        <TabPanel header="Sales">
          <LineChart title="Sales" labels={data['hourly_label']} data={data['vph']} dotData={data['pvph']} ylabel="COUNT" />
          <Grid container spacing={1} style={{ marginTop: '20px' }}>
            <Grid item xs={12}>
              <Typography variant="h5">Top Sales (24Hrs)</Typography>
            </Grid>
            {data['top_eth']['total_price'].map((item, index) => (
              <Grid item xs={12} md={6} lg={3}>
                <CollectionCard
                  index={index}
                  name={data['top_volume']['collection_name'][index]}
                  slug={data['top_volume']['collection_slug'][index]}
                  value={data['top_volume']['total_count'][index]}
                />
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      </TabView>
    </Paper>
  );
};
