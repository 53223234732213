import { Box, Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import Ads from 'ads';
import FeaturedProject from 'components/FeaturedProject';
import { Link } from 'react-router-dom';
import ToolDescriptions from 'ToolDescriptions';
export default () => {
  const tools = ToolDescriptions();
  return (
    <Container maxWidth="xl" style={{ marginTop: '16px', marginBottom: '16px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ height: '100%', width: '100%' }}>
          {/* <Paper  style={{ display: 'flex', height: '100%', width: '100%' }}> */}
          <Box display="flex" justifyContent="space-evenly" alignItems="center" m={2} width="100%">
            <Box display="flex" flexDirection="column">
              <Typography variant="h2">TokenTuna</Typography>
              <Typography variant="h6">Your Hub for Data Analytics On NFTS</Typography>
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography>Want to Use Our Tools?</Typography>
              <Button color="primary" variant="disabled">
                Purchase a Tuna Key
              </Button>
            </Box>
          </Box>
        </Grid>
        {Ads.map((ad, index) => (
          <Grid item xs={12} sm={6} md={3}>
            <FeaturedProject projectName={ad.projectName} projectDescription={ad.projectDescription} projectImgSrc={ad.projectImgSrc} />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography variant="h4">Currently Available Tools</Typography>
        </Grid>
        {tools.map((tool, index) => (
          <Grid item xs={12} md={4}>
            <Paper variant="outlined" style={{ height: '100%', width: '100%' }}>
              <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-between" height="100%" p={2}>
                <Box>
                  <Typography variant="h4" style={{ textAlign: 'center', width: '100%' }}>
                    {tool.title}
                  </Typography>
                  <Typography variant="h6" style={{ textAlign: 'center', width: '100%' }}>
                    {tool.subtitle}
                  </Typography>
                  {/* <Box flexBasis={0} flexGrow={1}>
                  <img style={{ width: '100%', height: '300px' }} src={tool.src} />
                </Box> */}
                  <Typography variant="body1" style={{ textAlign: 'left' }}>
                    {tool.description}
                  </Typography>
                </Box>
                <Button
                  component={Link}
                  to={tool.internalLink}
                  variant="outlined"
                  color="primary"
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                >
                  Use This Tool
                </Button>
              </Box>
            </Paper>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography variant="h6">More Coming Soon...</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
