import { Button } from '@material-ui/core';
import { Container, Grid } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import FeaturedProject from 'components/FeaturedProject';
import OpenSeaDataTable from 'components/OpenSeaDataTable';
import StatsTable from './components/StatsTable';
import Ads from 'ads';
import OpenSeaData from './SampleOpenSeaData';
import SalesTimeline from 'components/SalesTimeline';
import TopItems from './components/TopItems';
import FooterBar from 'components/FooterBar';
import graphData from './SampleGraphData.json';

export default () => {
  console.log(Ads);
  console.log(graphData);
  return (
    <Container maxWidth="xl" style={{ paddingTop: '24px', paddingBottom: '24px' }}>
      <Grid container spacing={2}>
        {Ads.map((ad, index) => (
          <Grid item xs={12} sm={6} md={3}>
            <FeaturedProject projectName={ad.projectName} projectDescription={ad.projectDescription} projectImgSrc={ad.projectImgSrc} />
          </Grid>
        ))}
        {/* <Grid item xs={12}>
          <SalesTimeline />
        </Grid> */}
        <Grid item xs={12}>
          <TopItems data={graphData} />
        </Grid>
        <Grid item xs={12}>
          {/* <OpenSeaDataTable data={OpenSeaData.data} columns={OpenSeaData.columns} options={OpenSeaData.options} /> */}
          <StatsTable data={OpenSeaData.data} columns={OpenSeaData.columns} columnFormats={OpenSeaData.columnFormats} />
        </Grid>
      </Grid>
    </Container>
  );
};
