import { Box, Divider, Paper, Typography } from '@material-ui/core';
import SocialsBar from 'components/SocialsBar';
import { usePageContext } from 'pages/WalletTracker/PageContext';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/primereact.css';
import { Tooltip } from 'primereact/tooltip';
import React, { useState } from 'react';
import { FaEthereum } from 'react-icons/fa';
import 'theme.css';
import NumberFormatter from 'utils/NumberFormatter';
import PercentageFormatter from 'utils/PercentageFormatter';
import { FilterMatchMode } from 'primereact/api';

const EthTemplate = (rowData, index) => {
  // console.log(index['field']);
  // console.log(rowData);
  return (
    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      {rowData[index['field']] ? (
        <>
          <FaEthereum />
          <NumberFormatter number={rowData[index['field']]} />
        </>
      ) : (
        '-'
      )}
    </Typography>
  );
};

const SocialsTemplate = (rowData, index) => {
  // console.log(index['field']);
  // console.log(rowData);
  return (
    <SocialsBar
      instagram={rowData['collection_instagram_username']}
      discord={rowData['collection_discord_url']}
      external={rowData['collection_external_url']}
      medium={rowData['collection_medium_username']}
      telegram={rowData['collection_telegram_url']}
      twitter={rowData['collection_twitter_username']}
      nft={rowData['permalink']}
      vertical
    />
  );
};

const ImageTemplate = (rowData, index) => {
  // console.log(index['field']);
  // console.log(rowData);
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent={'center'}>
      <img src={rowData['image_thumbnail_url']} style={{ maxWidth: '128px', maxHeight: '128px' }} />
      <Typography>{rowData[index['field']]}</Typography>
    </Box>
  );
};

const TraitsTemplate = (rowData, index) => {
  // console.log(index['field']);
  return (
    <Box display="flex" flexDirection="row" style={{ width: '288px' }} flexWrap="wrap">
      {rowData[index['field']].map((trait, i) => {
        if (trait['trait_type'] && trait['value'] && trait['trait_count'] && rowData['collection_count']) {
          return (
            <Paper
              variant="outlined"
              style={{ width: '80px', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '8px' }}
            >
              <Typography noWrap style={{ maxWidth: '75px' }} variant="caption">
                {trait['trait_type']}
              </Typography>
              <Divider style={{ width: '100%' }} />
              <Typography noWrap style={{ maxWidth: '75px' }} variant="body2">
                {trait['value']}
              </Typography>
              <Divider style={{ width: '100%' }} />
              <Typography variant="body2">
                <PercentageFormatter number={parseFloat(trait['trait_count']) / parseFloat(rowData['collection_count'])} />
              </Typography>
            </Paper>
          );
        }
      })}
    </Box>
  );
};

const PercentageTemplate = (rowData, index) => {
  if (!rowData[index['field']]) {
    return (
      <Typography variant="body1" color="textPrimary">
        -
      </Typography>
    );
  }
  const per = rowData[index['field']].toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 1
  });
  if (per.includes('-')) {
    return (
      <Typography variant="body1" color="secondary" style={{ display: 'flex', flexDirection: 'column' }}>
        <div>{per}</div>
      </Typography>
    );
  } else {
    return (
      <Typography variant="body1" color="primary" style={{ display: 'flex', flexDirection: 'column' }}>
        <div>{per}</div>
      </Typography>
    );
  }
};
const EthPercentageTemplate = (rowData, index) => {
  return (
    <Typography variant="body1">
      <FaEthereum />
      <NumberFormatter number={rowData[index['field']]} />
    </Typography>
  );
};
const ActionsTemplate = (rowData, index) => {
  const link = 'https://opensea.io/collection/' + rowData['slug'];
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        textDecoration: 'none',
        color: '#FFF'
      }}
    >
      <Button icon="pi pi-external-link" />
    </a>
  );
};

export default React.memo(({ data }) => {
  const { targetWallet, setTargetWallet } = usePageContext();
  console.log(data);
  const [tableFilter, setTableFilter] = useState({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } });
  const onGlobalFilterChange = (event, filtersKey) => {
    const value = event.target.value;
    let filters = { ...tableFilter };
    filters['global'].value = value;
    setTableFilter(filters);
  };
  const SearchBar = (filtersKey) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" style={{ wordBreak: 'break-all' }}>
          Target Wallet: {`${targetWallet}`}
        </Typography>
        <span className="p-input-icon-left" style={{ marginLeft: '16px' }}>
          <i className="pi pi-search" />
          <InputText
            // type="search"
            value={tableFilter['global'].value || ''}
            onChange={(e) => onGlobalFilterChange(e, filtersKey)}
            placeholder="Search"
            spellCheck={false}
          />
        </span>
      </div>
    );
  };
  return (
    <Paper variant="outlined">
      <Tooltip target=".nft" position="top" content="Art Asset" />
      <Tooltip target=".collection" position="top" content="Name of Collection NFT Belongs To" />
      <Tooltip target=".traits" position="top" content="The Attributes Assigned To NFT" />
      <Tooltip target=".num_sales" position="top" content="Total Number of Times An NFT Has Been Sold" />
      <Tooltip target=".cost" position="top" content="Price At Which NFT Was Last Purchased" />
      <Tooltip target=".average_price" position="top" content="The Current Average Price Of NFTS Being Sold In Collection" />
      <Tooltip target=".top_bid" position="top" content="The Current Top Bid" />
      <Tooltip
        target=".growth"
        position="top"
        content="Percentage Difference Between Last Sold Price And Current Average Collection Price"
      />
      <Tooltip target=".links" position="top" content="Relevant NFT Social Links" />
      <DataTable
        value={data}
        header={SearchBar}
        stripedRows
        filters={tableFilter}
        sortField="asset_growth"
        sortOrder={-1}
        responsiveLayout="stack"
        headerStyle={{ position: 'sticky', top: '0px' }}
      >
        <Column className="nft" sortable field="name" header="NFT" body={ImageTemplate} />
        <Column className="collection" sortable field="collection_name" header="Collection" />
        <Column className="traits" field="traits" header="Traits" body={TraitsTemplate} />
        <Column className="num_sales" sortable field="num_sales" header="# Of Sales" />
        <Column className="cost" sortable field="cost" header="Last Purchase" body={EthTemplate} />
        <Column className="average_price" sortable field="average_price" header="Avg Collection Price" body={EthTemplate} />
        <Column className="top_bid" sortable field="top_bid" header="Top Bid" body={EthTemplate} />
        <Column className="growth" sortable field="asset_growth" header="Estimated Growth" body={PercentageTemplate} />
        <Column className="links" field="collection_slug" header="Links" body={SocialsTemplate} />
      </DataTable>
    </Paper>
  );
});
